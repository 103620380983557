
import { defineComponent, reactive, ref, toRefs, onMounted } from 'vue'
import { digitUppercase } from '@/utils/index'
import { detailApi } from './api'
import {
  feeType,
  state,
  reason,
  classesType,
  billStatus,
  payChannel,
  rentType,
  workEndStatus,
  chargeType,
  contractBillType,
  overdueStatus,
  moneyType,
  Week
} from './config'
import dayjs from 'dayjs'

export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object
  },
  setup(props, { emit }) {
    const data = reactive({
      dialogVisible: props.visible,
      form: ref<any>(null),
      deductionRecord: ref<any>(null), // 扣费说明
      fillDeductionRecord: ref<any>(null), // 补扣费说明
      contractBill: ref<any>(null), // 账单信息
      contract: ref<any>(null), // 合同消息
      formRef: null
    })

    // 枚举
    const Enum = {
      feeType,
      state,
      reason,
      billStatus,
      rentType,
      payChannel,
      chargeType,
      workEndStatus,
      contractBillType,
      moneyType,
      classesType,
      overdueStatus
    }

    const handleClose = () => {
      emit('update:visible', false)
    }

    const weekTranstionCN = str => {
      const arr = str.split(',')
      const t = arr.map(i => Week[i])
      return t.join()
    }

    onMounted(() => {
      detailApi({ uuid: props.rowData.uuid }).then(res => {
        data.form = res.data
        data.deductionRecord = res.data.deductionRecord || {}
        data.fillDeductionRecord = res.data.fillDeductionRecord || {}
        data.contractBill = res.data.contractBill || {}
        data.contract = res.data.contract || {}
      })
    })

    return {
      ...toRefs(data),
      ...Enum,
      digitUppercase,
      dayjs,
      weekTranstionCN,
      handleClose
    }
  }
})
